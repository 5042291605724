import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import moment from 'moment';
import {  first, forkJoin} from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { InquiryService } from 'src/app/core/services/inquiry.service';
import { LeadService } from 'src/app/core/services/lead.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { LocationService } from 'src/app/core/services/location.service';
import { PostService } from 'src/app/core/services/post.service';
import { PropertyService } from 'src/app/core/services/property.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { TypeService } from 'src/app/core/services/type.service';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import { Select, initTE } from "tw-elements";
import { MetaService } from 'src/app/core/services/meta.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  @ViewChild('youtubeContainer1') youtubeContainer1: ElementRef | undefined;
  @ViewChild('youtubeContainer2') youtubeContainer2: ElementRef | undefined;
  @ViewChild('googleMap') googleMap: ElementRef | undefined;
  imageLoaded: boolean = false;
  active_status = 1;
  active_tab = null;
  showPopup =false;
  showPopupToast =false;
  types: any;
  isFilterClicked = false;
  showDropdown = false; // Initially, the dropdown is hidden
  locations: any;
  properties = [] as any;
  countProperties = [] as any;
  numApartmentForRent: number = 0;
  numHouseForRent: number = 0;
  numApartmentForSale : number = 0;
  numHouseForSale: number = 0;
  numApartment: number = 0;
  numCondo: number = 0;
  numHouse: number = 0;
  numDevelopment: number = 0;
  numFurnished: number = 0;
  numLand: number = 0;
  numUnfurnished: number = 0;
  featuredProperties = [] as any;
  agentProperties = [] as any;
  posts = [] as any;
  submitted = false;
  bedrooms = [] as any;
  budget = [] as any;
  isFavorite: boolean[] = [];
  public searchResults = new Set();
  navOpen = false;
  observable: any;
  smSearch = false;
  /*
    Form input
  */
  searchPropertyForm = this.fb.group({
    max_price: [null] as any,
    bedroom: [null] as any,
    type_id: new Array([]) as any,
    area: [null] as any
  });

  popupForm = this.fb.group({
    name: [null, [Validators.required]] as any,
    email: [null, [Validators.required, Validators.email]] as any,
    phone: this.fb.array([this.fb.control('', [Validators.required, Validators.pattern('^[0-9]+$'), Validators.maxLength(13)])]) as any,
    source:['PopUp-Website']
  });

  inquiryForm = this.fb.group({
    contact_id: [null] as any,
    inquiry_type: [null, Validators.required] as any,
    name: [null, Validators.required] as any,
    phone: this.fb.array([this.fb.control('', [Validators.required, Validators.minLength(10)])]),   
    source: ['Website'],
    consent: [false, Validators.requiredTrue],
    note: [null],
  });

  searchResult = history.state.data;
  slider: any;
  defaultTransform: any;
  multiSelect: any;
  userId: any;
  user: any;
  favorites: any = [];
  finalSubmit = false;

  validatePhoneNumberLength(control: AbstractControl): ValidationErrors | null {
    const value = control.value as string;
    if (value && value.length < 10) {
      return { minLength: true };
    }
    return null;
  }
  
  goNext() {
    this.defaultTransform = this.defaultTransform - 398;
    if (Math.abs(this.defaultTransform) >= this.slider.scrollWidth / 1.7) this.defaultTransform = 0;
    this.slider.style.transform = "translateX(" + this.defaultTransform + "px)";
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  goPrev() {
    if (Math.abs(this.defaultTransform) === 0) this.defaultTransform = 0;
    else this.defaultTransform = this.defaultTransform + 398;
    this.slider.style.transform = "translateX(" + this.defaultTransform + "px)";
  }

  constructor (
    private metaService: MetaService, 
    private propertyService: PropertyService,
    private loaderService: LoaderService, 
    private storageService: StorageService, 
    private authService: AuthService, 
    private postService: PostService,
    private leadService: LeadService, 
    private inquiryService: InquiryService, 
    private locationService: LocationService, 
    private typeService: TypeService, 
    private fb: FormBuilder, 
    public router: Router,
  ) {}

  ngOnInit() {
    this.metaService.createCanonicalURL()
    this.metaService.updateTitle('Live Ethio: Properties for Rent and Sale in Addis Ababa Ethiopia')
    this.metaService.updateDescription('Buy, sell, and rent properties in Addis Ababa, Ethiopia with Live Ethio Real Estate. Browse houses, apartments, condominiums & more.')
    this.metaService.updateURL(window.location.href)
    initTE({ Select });
    this.slider = document.getElementById("slider");
    this.defaultTransform = 0
    
    /*
      Bedroom Range for filter
    */
    for (let i = 1; i < 11; i++) {
      this.bedrooms.push(i)
    }

    /*
      Determines Price Range for filter 
    */
    const ranges = [
      { start: 10000, end: 45000, step: 10000 },
      { start: 50000, end: 950000, step: 50000 },
      { start: 1000000, end: 95000000, step: 1000000 },
      { start: 100000000, end: 500000000, step: 10000000 }
    ];
    
    for (const range of ranges) {
      for (let i = range.start; i <= range.end; i += range.step) {
        this.budget.push(i);
      }
    }

    /*
      Selected properties to showcase in the "HomePage"
    */
    const specificPropertyIds = [1147, 1104, 1077, 115, 101, 281];
    const commonProperties = {
      published_properties: this.propertyService.getPublishedCount(),
      // featured_properties: this.propertyService.getFeatured(),
    };

    if (this.storageService.isLoggedIn() && !this.storageService.tokenExpired()) {
      this.authService.getLoggedInUser().subscribe({
        next: (response:any) =>{
          console.log(response);
          this.authService.setAllUserDetails(response)
        }
      })
      // this.authService.setAllUserDetails(this.authService.LoggedInUser())

      this.observable = forkJoin({
        ...commonProperties,
      });
    } else {
      this.observable = forkJoin({
        ...commonProperties,
        // specific_properties: forkJoin(
        //   specificPropertyIds.map(id => this.propertyService.get(id))
        // ),
      });
    }

    this.observable.subscribe({
      next: (value: any) => {
        this.resetCounts();
    
        // this.featuredProperties = value.specific_properties;
        this.properties = value.published_properties;
    
        /*
          Counter for "What Are You Looking For?"
        */
        for (let property of value.published_properties) {
          for (let prop_type of property.type || []) {
            if (prop_type.name == 'Apartment') this.numApartment += 1;
            else if (prop_type.name == 'House') this.numHouse += 1;
            else if (prop_type.name == 'Condo') this.numCondo += 1;
            else if (prop_type.name == 'New Development') this.numDevelopment += 1;
            else if (prop_type.name == 'Land') this.numLand += 1;
          }
    
          if (property?.label?.name == 'Furnished') this.numFurnished += 1;
          else if (property?.label?.name == 'Unfurnished') this.numUnfurnished += 1;
  
          for (let type of property.type || []) {
            if (type.name === 'Apartment' && property.status?.name === 'For Rent') this.numApartmentForRent += 1;
            else if (type.name === 'House' && property.status?.name === 'For Rent') this.numHouseForRent += 1;
            else if (type.name === 'House' && property.status?.name === 'For Sale') this.numHouseForSale += 1;
            else if (type.name === 'Apartment' && property.status?.name === 'For Sale') this.numApartmentForSale += 1;
          }
        }
      },
      complete: () => {
        // setTimeout(() => {
        //   this.loadYouTubeIframe();
        // }, 5000)
      }

    });

    this.propertyService.getFeatured().subscribe(featuredProperties => {
      this.featuredProperties = featuredProperties;
    });

    this.typeService.getAll().pipe(first()).subscribe(types => {
      this.types = types;
    });
    this.locationService.getAll().pipe(first()).subscribe(locations => {
      this.locations = locations;
    });
    this.postService.getAll().pipe(first()).subscribe(posts => {
      this.posts = posts;
    });

    setTimeout(() => {
      this.showPopup = true;
    }, 7000);
  }

  // setMetaTags() {
  //   // Set page title
  //   this.title.setTitle('My Dynamic Page Title');

  //   // Set meta description
  //   this.meta.updateTag({
  //     name: 'description',
  //     content: 'This is a dynamically set meta description for SEO.'
  //   });

  //   // Set Open Graph description
  //   this.meta.updateTag({
  //     property: 'og:description',
  //     content: 'This is a dynamically set Open Graph description for social media.'
  //   });

  //   // Set other Open Graph tags (optional)
  //   this.meta.updateTag({
  //     property: 'og:title',
  //     content: 'My Dynamic Page Title'
  //   });
  //   this.meta.updateTag({
  //     property: 'og:type',
  //     content: 'website'
  //   });
  //   this.meta.updateTag({
  //     property: 'og:url',
  //     content: window.location.href
  //   });
  //   this.meta.updateTag({
  //     property: 'og:image',
  //     content: 'https://example.com/my-image.jpg' // Replace with your image URL
  //   });
  // }
  get f() { return this.inquiryForm.controls; }
  get phone() {
    return this.inquiryForm.controls["phone"] as unknown as FormArray;
  }

  // Helper function to reset the counter
  resetCounts() {
    this.numApartment = this.numHouse = this.numCondo = this.numDevelopment = this.numLand = 0;
    this.numFurnished = this.numUnfurnished = 0;
    this.numApartmentForRent = this.numApartmentForSale = this.numHouseForRent = this.numHouseForSale = 0;
  }
  /*
    Determine which tab is active between:
      All Status
      For Rent
      For Sale
  */
  tabActive(num: any, value: any) {
    this.active_status = num;
    this.active_tab = value;
  }

  tabActiveSm(event: any) {
    this.active_tab = event.target.value;
  }

  // Filter based on user search criteria
  onSearch() {
    this.submitted = true;
    var search_type = this.searchPropertyForm.controls.type_id.value as Array<number>

    if (this.searchPropertyForm.invalid) {
      return;
    }

    this.propertyService.getPublishedOpt().subscribe(properties => {
      this.properties = properties;
      this.loaderService.show();
      for (let property of this.properties) {
        var beds = this.searchPropertyForm.controls.bedroom.value as Array<number>;
        var area = this.searchPropertyForm.controls.area.value as Array<number>;
        var budget = this.searchPropertyForm.controls.max_price.value as number ?? property.price;
  
        if (this.active_tab == "Land") {
          for (let prop_type of property.type) {
            if (area) {
              if (area.includes(property.location.id) && +property.price <= +budget && prop_type.name == "Land") {
                this.searchResults.add(property)
              }
            } else {
              if (+property.price <= +budget && prop_type.name == "Land") {
                this.searchResults.add(property)
              }
            }
          }
        }
        else {
          if (search_type.length != 0 && beds) {
            for (let prop_type of property.type) {
              for (let type of search_type) {
                if (prop_type.id == type && beds.includes(property.bedrooms) && +property.price <= +budget && property.status.name == (this.active_tab ?? property.status.name)) {
                  this.searchResults.add(property)
                }
              }
            }
          }
          else if (search_type.length != 0 && !beds) {
            for (let prop_type of property.type) {
              for (let type of search_type) {
                if (prop_type.id == type && +property.price <= +budget && property.status.name == (this.active_tab ?? property.status.name)) {
                  this.searchResults.add(property)
                }
              }
            }
          }
          else if (search_type.length == 0 && beds) {
            if (beds.includes(property.bedrooms) && +property.price <= +budget && property.status.name == (this.active_tab ?? property.status.name)) {
              this.searchResults.add(property)
            }
          } else if (+property.price <= +budget && property.status.name == (this.active_tab ?? property.status.name)) {
            this.searchResults.add(property)
          }
        }
      }
      this.sendData();
    });
  }

  sendData() {
    this.router.navigateByUrl("/site/property/search-results", { state: { data: this.searchResults } })
  }

  /*
    Handles the submitted inquiry and sends a notification to the "admin" email address
  */
  onInquiry() {
    
    this.submitted = true;
    if (this.inquiryForm.invalid) {
      return;
    }

    this.inquiryForm.controls['inquiry_type'].disable();
    this.inquiryForm.controls['consent'].disable();
    this.inquiryForm.controls['contact_id'].disable();

    this.leadService.createPopUpLead(this.inquiryForm.value).subscribe(res => {
      this.inquiryForm.controls['inquiry_type'].enable();
      this.inquiryForm.controls['note'].enable();
      this.inquiryForm.controls['contact_id'].enable();
      this.inquiryForm.controls['name'].disable();
      this.inquiryForm.controls['phone'].disable();
      this.inquiryForm.controls['source'].disable();
      this.inquiryForm.patchValue({
        contact_id: res.id
      })
      this.inquiryService.create(this.inquiryForm.value).subscribe(res => {
        this.submitted = false;
        this.finalSubmit = true;
        this.inquiryForm.reset();
      })
    });
  }

  sendEmail(e: Event) {
    e.preventDefault();

    const inquiryTypeValue = this.inquiryForm.get('inquiry_type')?.value;
    const phoneControl = this.inquiryForm.get('phone') as FormArray;
    const phoneNumber = phoneControl.at(0).value;
    const note = this.inquiryForm.value.note;

    if (!inquiryTypeValue) {
        console.error('Inquiry type is required.');
        return;
    }

    const templateParams = {
        user_name: this.inquiryForm.value.name,
        fields: {
            inquiry_type: inquiryTypeValue,
            user_phoneNumber: phoneNumber,
            note: note ? note : "No additional notes were added"
        }
    };

    emailjs
        .send('service_78tfotb', 'template_a82oqbn', templateParams, '37zN44Jw6FGstpZ-T')
        .then((result: EmailJSResponseStatus) => {
          this.submitted = false;
          this.finalSubmit = true;
          this.inquiryForm.reset();
        });
  } 

  toLocale(num: any) {
    return Math.round(parseInt(num)).toLocaleString("en-US")
  }

  goToDetails(id: any) {
    const url = this.router.serializeUrl( this.router.createUrlTree(['/site/property-details/' + id]));
    window.open(url, '_blank');
  }

  fromNow(date: any) {
    return moment(date).fromNow();
  }

  goToProfile(id: any, role: any, username: any) {
    this.router.navigateByUrl("/" + role + "/" + id + "/" + username)
  }

  share(prop: any, val: any) {
    prop.shared = !prop.shared;
    var strCmd = "document.getElementById('shared-popup" + prop.id + "').style.display = 'none'";
    var waitseconds = 2;
    var timeOutPeriod = waitseconds * 1000;
    setTimeout(strCmd, timeOutPeriod);
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = window.location.origin + val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  seeMoreFeatured() {
    this.router.navigateByUrl("/site/property");
  }

  seeMoreAgent() {
    this.searchResults = new Set(this.agentProperties);
    this.sendData();
  }

  changeMe() {
    let sel = document.getElementById('budget-sel')!;
    sel.style.color = "#1f2937";
  }

  filterSearch(event: any) {
    this.isFilterClicked = true;
  }
  // ngAfterViewInit(){
    // setTimeout(() => {
    //   this.loadYouTubeIframe();
    // }, 10000);
  // }

  loadYouTubeIframe() {
    // Replace 'YOUR_YOUTUBE_VIDEO_ID' with the actual YouTube video ID
    // const youtubeVideoId = 'YOUR_YOUTUBE_VIDEO_ID';

    // Create the YouTube iframe
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.youtube.com/embed/x3y7u0p80a8?autoplay=0&mute=1&loop=0`;
    iframe.allowFullscreen = true;
    iframe.className ='w-full h-full rounded-md';

    // Append the iframe to the container
    this.youtubeContainer2!.nativeElement.appendChild(iframe);


    const iframe2 = document.createElement('iframe');
    iframe2.src = `https://www.youtube.com/embed/w_g7Swua7to?autoplay=0&mute=1&loop=0`;
    iframe2.allowFullscreen = true;
    iframe2.className ='w-full h-full rounded-md';

    // Append the iframe to the container
    this.youtubeContainer1!.nativeElement.appendChild(iframe2);


    const iframe3 = document.createElement('iframe');
    iframe3.src = `https://www.google.com/maps/d/embed?mid=1onQBH5F9rEzu65Xt-8-IRoVl9Gt1gFg&ehbc=2E312F`;
    iframe3.width = '100%';
    iframe3.height = '300px';

    // Append the iframe to the container
    this.googleMap!.nativeElement.appendChild(iframe3);


  }
  onPopupSubmit(){
    if(this.popupForm.valid){
      this.leadService.create(this.popupForm.value).subscribe(res => {
        // this.showPopup=false;
        this.popupForm.reset();
        this.showPopupToast = true;
        this.showPopup =false;
        setTimeout(() => {
          this.showPopupToast = false;
          this.showPopup =false;
        }, 5000)
      });
    }
    this.popupForm.markAllAsTouched();
  }
}
